
















import {
  defineComponent,
  ref,
  onMounted,
  PropType,
  onUnmounted,
} from '@vue/composition-api'
import gsap, { TimelineLite } from 'gsap'

import UiLoader from '@/components/ui/Loader.vue'

interface MasonryItem extends Record<string, unknown> {
  masonryKey: string
}

export default defineComponent({
  name: 'UiMasonry',
  components: { UiLoader },
  props: {
    items: {
      type: Array as PropType<Record<string, unknown>[]>,
      default: () => [],
    },
    keyProp: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const rootElRef = ref<HTMLElement | null>(null)
    const isLoadingRef = ref(true)
    const masonryItems: MasonryItem[] = props.items.map((item, index) => ({
      ...item,
      masonryKey:
        typeof item[props.keyProp] === 'string'
          ? (item[props.keyProp] as string)
          : index.toString(),
    }))
    let masonry: any

    // Init masonry layout
    const initMasonry = async () => {
      const Masonry = await import(
        /* webpackChunkName: 'vendor-masonry-layout' */ 'masonry-layout'
      ).then(module => module.default)

      masonry = new Masonry(rootElRef.value, {
        itemSelector: '.masonry__item',
        columnWidth: '.masonry__sizer',
        percentPosition: true,
        transitionDuration: 0,
        horizontalOrder: true,
      })

      // Play reveal animation
      const tl = initTl()
      tl.play()
    }

    // Init timeline for reveal animation
    const initTl = (): TimelineLite => {
      const itemsEl = rootElRef.value?.querySelectorAll('.masonry__item')
      const loaderEl = rootElRef.value?.querySelectorAll('.masonry__loader')
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([itemsEl, loaderEl], { clearProps: 'opacity' })
          isLoadingRef.value = false
        },
      })

      if (itemsEl && loaderEl) {
        tl.set(rootElRef.value, { opacity: 1 })
          .to(loaderEl, { opacity: 0, duration: 0.5 })
          .fromTo(
            itemsEl,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, stagger: 0.05 },
            '<+=0.3'
          )
      }

      return tl
    }

    // When new items are added to the grid, refresh the layout to display everything correctly
    // Only new items should be handled
    const refreshLayout = () => {
      if (!masonry || !rootElRef.value) {
        return
      }

      // Get news items (since they're not in the masonry, they don't have the style attr)
      const itemsEl = rootElRef.value.querySelectorAll(
        '.masonry__item:not([style])'
      )
      const tl = gsap.timeline()

      tl.set(itemsEl, { opacity: 0, y: 20 })
        .add(() => {
          masonry.reloadItems()
        })
        .add(() => {
          // Safari needs timeout otherwise the layout is not performed
          // #332 was fixed but not on Safari
          masonry.layout()
        }, '+=0.5')
        .to(itemsEl, { opacity: 1, y: 0, stagger: 0.05 })
    }

    onMounted(() => initMasonry())

    onUnmounted(() => {
      // Destroy masonry instance
      masonry?.destroy()
    })

    return {
      rootElRef,
      isLoadingRef,
      masonryItems,
      masonry,
      refreshLayout,
    }
  },
})
